import request from '@/utils/request'

export function generateOtp(data) {
    return request({
        url: '/api/otp/generate',
        method: 'get',
        params: data
    })
}

export function getBoard(data) {
    return request({
        url: '/api/otp/getBoard',
        method: 'get',
        params: data
    })
}

export function VerifyOtp(data) {
    return request({
        url: '/api/otp/vertify',
        method: 'get',
        params: data
    })
}

export function relateSearch(data){
    return request({
        url: '/api/relate/search',
        method: 'post',
        params: data
    })
}


export function relateAddOrUpdate(param,data){
    return request({
        url: '/api/relate/addOrUpdate',
        method: 'post',
        params: param,
        data
    })
}

